
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  UnwrapRef,
  toRefs,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  
  setup(){
      const store = useStore();
      const all_retailer_sites = computed(() => store.getters["Option/all_retailer_sites"]);

  }
 
});
